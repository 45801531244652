.main-rates {
    .mainhead {
        h2 {
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
    }

    .mainhead {
        h2 {
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
    }

    .mainssss {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            background-color: transparent;
            color: #FFF;
            border-bottom: 1px solid var(--Primary-Blue, #1777E2);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 0px;
        }

        .nav-link {
            color: #575757;

            text-align: center;

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .nav.nav-pills {
            border-bottom: 1px solid #575757 !important;
            gap: 30px;
            width: 50%;
        }

        .parentbtn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .reject {
                border-radius: 6px;
                opacity: 0.3;
                background: var(--Red, #C93636);
                padding: 11px 14px;
                width: 100%;
                border: none;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
            }

            .approve {
                border-radius: 6px;
                opacity: 0.3;
                background: #50BF29;
                padding: 11px 14px;
                width: 100%;
                border: none;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
            }
        }
    }

    .maincard {
        border-radius: 10px;

        padding: 5px 7px 5px 5px;

        width: 100%;
        margin-top: 15px;

        .one {
            border-radius: 8px;
            border: 1px solid #1E1F20 !important;
            margin-bottom: 5px;
            background: #141516 !important;

            p {
                color: #949494;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 7px;
            }
            
        }

        .parent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 30px;
            margin-bottom: 5px;
            background: #191A1B;
            border: 1px solid #1B1C1D;
            margin-bottom: 5px;

            .first {
                width: 16.6%;


            }

            .second {
                width: 16.6%;
            }

            .third {
                width: 16.6%;
            }

            .fourth {
                width: 16.6%;
            }

            .five {
                width: 16.6%;
            }

            .six {
                width: 16.6%;
            }

            h4 {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 171.429% */
                letter-spacing: 0.28px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 6px;

                span {
                    color: #949494;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }

    }

    .pagi {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;

        .left {
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #81828a;
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 22px;

            .pagination {
                display: flex;
                gap: 10px;

                .page-link {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    background-color: transparent;
                    line-height: 100%;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                }

                .page-item.active .page-link {
                    border-radius: 8px;
                    border: 1.5px solid #1777E2;
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    background: #141516;
                }
            }
        }
    }

    .formbl {
        display: none;
    }

    .mainheadings {
        padding: 20px 0px;

        p {
            color: #BEBEBE;
            ;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }
    }

    .maininput {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        input {
            border-radius: 6px;
            border: 1px solid #2B2C2E;
            padding: 19px 15px;
            width: 172px;
            background: #141516;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &::placeholder {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        p {
            color: #BEBEBE;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }
    }

    .save {
        padding: 11px 67px;
        border-radius: 6px;
        background: #1777E2;
        width: 266px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        margin-top: 35px;
        border: none;
    }
}

.edit {
    .modal-dialog {
        max-width: 451px;

        .modal-content {
            border-radius: 10px;
            border: 1px solid #1B1C1D;
            background: #141516;
            padding: 5px 5px 20px 5px;

            .modal-header {
                border-radius: 8px;
                background: #191A1B;
                display: flex;
                padding: 18px 15px 18px 148px;
                border-bottom: none !important;

                .modal-title {
                    color: #FFF;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .btn-close {
                    background: url(../../assets/close.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: contain;
                    width: 30px;
                    height: 30px;
                    box-shadow: none !important;
                    opacity: unset;
                    position: absolute;
                    padding: 0px;
                    margin: 0px;
                    top: 49px;
                    right: 31px;
                }
            }

            .modal-body {
                padding: 0px;

                .main {
                    margin: 32px 0px;

                    p {
                        color: #BEBEBE;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }

                    input {
                        border-radius: 6px;
                        border: 1px solid #2B2C2E;
                        background: #141516;
                        padding: 19px 15px;
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-top: 10px;
                        width: 100%;
                        max-width: 411px;

                        &::placeholder {
                            padding: 19px 15px;
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }

                .endbtn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 15px;

                    button {
                        border-radius: 6px;
                        border: 1px solid #2C2C2C;
                        padding: 16px 25px;
                        background: #141516;
                        color: var(--Primary-Blue, #1777E2);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                        width: 100%;

                        &:hover {
                            border-radius: 6px;
                            background: var(--Primary-Blue, #1777E2);
                            padding: 16px 25px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

}

@media(max-width:600px) {
    .edit .modal-dialog .modal-content .modal-header {
        padding: 18px 15px 18px 90px;
        ;
    }

    .formbl {
        display: block !important;
    }

    .main-rates .maincard {
        display: none;
    }

    .main-rates .mainhead h2 {
        font-size: 20px !important;
        text-align: start;
    }

    .main-rates .mainssss .nav.nav-pills {
        width: 100%;
        flex-wrap: nowrap;
    }

    .main-rates .mainssss .nav-link {
        font-size: 10px;
    }

    .formobilecard {
        border-radius: 8px;
        border: 1px solid #1E1F20;
        padding: 20px 15px;
        background: #141516;

        .mblinner {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 27px;

            .left {
                h5 {
                    color: #949494;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 14px;
                }

                p {
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .for-width {
                width: 84px !important;
            }

            .right {
                h5 {
                    color: #949494;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 14px;
                }

                p {
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
}
.cp{
                cursor: pointer !important ;
            }