.apexcharts-toolbar{
    display: none !important;
}

.apexcharts-text tspan{
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #909090;
}


.apexcharts-tooltip {
    background: #1777E2;
    color: #FFFFFF;
  }
  