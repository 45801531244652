.product {
  .historyss {
    background: #191a1b;
    border-radius: 8px;
    padding: 20px;
    h4.headng {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #ffffff;
      /* padding: 20px; */
      /* padding-top: 23px; */
      /* padding-bottom: 23px; */
      /* padding-left: 20px; */
    }
  }

  .row.main_rowdiv {
    background: #141516;
    border: 1px solid #1e1f20;
    border-radius: 8px;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    p.event {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: #8e8e8e;
      padding-bottom: 8px;
    }
    h6.time_date {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #ffffff;
      span.mute {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #bebebe;
        padding-left: 6px;
      }
    }
  }
  .mainfoot {
    margin-top: 28.5px;
    display: flex;
    justify-content: center;
  }
  .right {
    text-align: center;
    .pagination {
      .page-link {
        background: transparent;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #757575;

        &:focus {
          box-shadow: none;
        }

        &.active {
          // background: #009DE0;
          border-radius: 5px;
          color: #fff;

          background: #141516;
          border: 1.5px solid #1777e2;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          color: #ffffff;
        }
      }
    }
  }
  .parent-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: #ffffff;
      margin-bottom: 25px;
    }
    .dropdown {
      margin-right: 10px;
      button {
        background: #151515;
        border: 1px solid #009de0;
        border-radius: 8px;
        padding: 8px 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #ffffff;
      }
      .dropdown-menu {
        background: #151515;
        border: 1px solid #292929;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 20px;
        .input-field {
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
          }
          .form-check-input {
            position: static !important;
            margin-top: 4px;
            margin-right: 8px;
            background-color: #151515;
            border: 2px solid #414141;
            border-radius: 4px;
            &:checked {
              background-color: #009de0;
              border: 2px solid #009de0;
              border-radius: 4px;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .table-responsive {
    background: #1e1e1e;
    border: 1px solid #292929;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    table {
      thead {
        background: #212121;
        border-bottom: 1px solid #292929;
      }

      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #767676 !important;
      }

      td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff !important;
        border-top: 1px solid #292929 !important;
      }

      .id {
        color: #767676 !important;
      }
    }

    .btn-selling {
      background: #00ae11;
      border-radius: 30px;
      padding: 5px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #ffffff;
      text-align: center;
    }
  }

  // switch input scss here .......
  .tgl {
    display: none;

    // add default box-sizing for this scope
    &,
    &:after,
    &:before,
    & *,
    & *:after,
    & *:before,
    & + .tgl-btn {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }

    + .tgl-btn {
      outline: 0;
      display: block;
      width: 37px;
      height: 20px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:after,
      &:before {
        position: relative;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
      }

      margin-top: 17px;

      &:after {
        left: 0;
      }

      &:before {
        display: none;
      }
    }

    &:checked + .tgl-btn:after {
      left: 50%;
      background: #fbfbfb;
    }
  }
}

// Product edit sidebar scss here........
.sidebar-editproduct {
  width: 100%;

  .offcanvas-title {
    margin-top: 34px !important;
    margin-bottom: 33px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    margin: 0;
  }
  .bannerss {
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: #009de0;
      margin-bottom: 8px;
    }

    .list-inline-item {
      text-align: center;
    }

    .item-upload {
      background: #1e1e1e;
      border: 1px dashed #313131;
      border-radius: 8px;
      padding: 35px;
      margin-bottom: 20px;

      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        color: #ffffff;
        display: block;
        margin-top: 8px;
        margin-bottom: 5px;
      }
    }
  }

  .filedinput {
    .catagoryfield {
      position: relative;

      img {
        position: absolute;
        top: 20px;
        right: 18px;
      }
    }

    .productarea {
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      padding: 15px;
      height: 120px;
      color: #fff;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      color: #009de0;
      margin-bottom: 8px;
    }

    input {
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      margin-bottom: 25px;
      color: #ffffff;
      // color: #525252;
    }
  }
  .buttonss {
    display: flex;
    button {
      background: #313131;
      border-radius: 8px;
      padding: 12px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;
      white-space: nowrap;
      // margin-top: 25px;
      margin-bottom: 10px;
      border: none;
      &:hover {
        background: #009de0;
      }
    }
  }
  .producttag {
    background: #1e1e1e;
    border: 1px solid #313131;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;

    .babycare {
      background: #313131;
      border-radius: 5px;
      padding: 5px 10px;
      img {
        margin-left: 8px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        margin: 0px;

        color: #ffffff !important;
      }
    }
  }
  .dropdown {
    .btn {
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #525252;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-menu {
      width: 100%;
      background: #1e1e1e;
      border: 1px solid #313131;
      border-radius: 8px;
      color: white;
    }
  }
}
@media (max-width: 1100px) {
  .product .row.main_rowdiv p.event {
    font-size: 12px;
    line-height: 13px;

    padding-bottom: 8px;
  }
  .product .row.main_rowdiv h6.time_date {
    font-size: 14px;
    line-height: 15px;

    padding-bottom: 10px;
  }
  .product .row.main_rowdiv {
    // flex-direction: row-reverse;
  }
}



.css-1hxrwmy-MuiButtonBase-root-MuiPaginationItem-root{
      color: #fff !important;
}