.main-login {
    height: 100vh;
    background: #000 !important;
    .inner-logo {
        // img {
        //     width: 150px;
        // }
    }
.dhvbyudv{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
    .form-check-input {
        position: absolute;
        margin-top: 9px;
        margin-left: 0;
    }
    .cmn-tile-style {
        // background: #181936;
        // border: 1px solid #2C2D49;
        // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
        // border-radius: 20px;
        // padding: 30px;
        // margin-top: 30px;
        // width: 100%;
        width: 500px;
        margin: 0 auto;
        margin-top: 150px;
        h2 {

            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 28px !important;
            line-height: 100%;
            color: #FFFFFF;
        }
        .cccc{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #525252;
            margin-top: 5px;
        }

        h6 {
            font-weight: normal;
            color: #636363;
        }
        label {
        
            padding-top: 30px;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 170% !important;
            color: #009DE0 !important;
        }
        
        input {
            box-sizing: border-box;
            height: 65px;
            border-radius: 14px;
            padding: 18px 15px;
            background: #151515 !important;
            border: 1px solid #313131 !important;
            border-radius: 8px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
            &::placeholder {
                color: #525252;
            }
            &:focus{
                box-shadow: none;
            }
        }
        .btn-login{
            background: #009DE0 !important;
            border-radius: 8px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFFFFF;
            box-shadow: none;
            padding: 20px;
           
        }
        .keep-label{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF !important;
        }
        .forgot{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-decoration-line: underline !important;
            color: #009DE0;
        }
    }

    .inputErrors{
        color: red;
    }
}
.upper-hh{
        position: relative;
}
.upper-nn{
    position: absolute;
    top: 22px;
    left: 13px;
}
.upper-nnn{
    position: absolute;
    top: 19px;
    left: 13px;
}

.upper-nnnn{
    position: absolute;
    top: 15px;
    right: 13px;
}

